import { useState, useContext } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../components/predefinedBottomRanges';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExportExcel = ({ apiurl }) => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [alert, setAlert] = useState({ open: false, type: 'success', msg: '', });
    const [open, setOpen] = useState(false);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    const fetchData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/${apiurl}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
            params: {
                startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
            }
        }).then((response) => {
            const nestedData = response.data.data;

            // Flatten the data
            const flattenedData = [];
            nestedData.forEach(item => {
                const baseData = {
                    ...item['รายละเอียดลูกค้า'],
                    ...item['สถานะการซื้อขาย']
                };

                if (item['สถานะการติดตาม'] && item['สถานะการติดตาม'].length > 0) {
                    item['สถานะการติดตาม'].forEach(followUp => {
                        flattenedData.push({
                            ...baseData,
                            "วันทีติดตาม": followUp["วันทีติดตาม"],
                            "ชื่อการติดตาม": followUp["ชื่อการติดตาม"],
                            "รายละเอียดการติดตาม": followUp["รายละเอียดการติดตาม"],
                            "ประเภทการติดตาม": followUp["ประเภทการติดตาม"]
                        });
                    });
                } else {
                    flattenedData.push(baseData);
                }
            });

            // Convert flattened data to Excel
            const ws = XLSX.utils.json_to_sheet(flattenedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Save the Excel file
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            saveAs(blob, 'data.xlsx');

            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            if (err.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            }
        })
    }

    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}>Export as Excel</Button>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='sm'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>เลือกวันที่ต้องการส่งออกข้อมูล</h2>
                    <div>
                        <DateRangePicker
                            //placement="bottomEnd"
                            size="lg"
                            placeholder="เลือกช่วงเวลา"
                            value={[filterDate.startDate, filterDate.endDate]}
                            onChange={(value) => {
                                setFilterDate({
                                    startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                    endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                                });
                            }}
                            ranges={predefinedBottomRanges}

                        />
                    </div>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button disabled={isLoading} className='btn btn-danger' onClick={fetchData}>ส่งออกข้อมูล</Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar
                className='z-50'
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => setAlert({ ...alert, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </>
    );

}

export default ExportExcel;