import { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Slider from '@mui/material/Slider';
//import InputColor from 'react-input-color';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SketchPicker } from 'react-color';

const ElementSettings = ({ element, onUpdate }) => {

    const [settings, setSettings] = useState(element);

    useEffect(() => {
        onUpdate(settings);
    }, [settings, onUpdate]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
    };

    const handleValidationChange = (e) => {
        const { name, value } = e.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            validation: { ...prevSettings.validation, [name]: value }
        }));
    };

    const handleOptionChange = (index, type, value) => {
        const newOptions = [...settings.options];
        newOptions[index][type] = value;
        setSettings(prevSettings => ({ ...prevSettings, options: newOptions }));
    };

    const handleAddOption = () => {
        if (!settings.options) {
            setSettings(prevSettings => ({ ...prevSettings, options: [] }));
        }
        const newOption = { id: Date.now(), label: '', value: '' };
        setSettings(prevSettings => ({ ...prevSettings, options: [...prevSettings.options, newOption] }));
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...settings.options];
        newOptions.splice(index, 1);
        setSettings(prevSettings => ({ ...prevSettings, options: newOptions }));
    };

    const handleAddImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setSettings(prevSettings => ({ ...prevSettings, image: e.target.result }));
        };
        reader.onerror = (err) => {
            console.log(err);
            reader.abort();
        }
        reader.onabort = (err) => {
            console.log(err);
            reader.abort();
        }
        reader.readAsDataURL(file);
    };

    const handleDropImage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setSettings(prevSettings => ({ ...prevSettings, image: e.target.result }));
        };
        reader.onerror = (err) => {
            console.log(err);
            reader.abort();
        }
        reader.onabort = (err) => {
            console.log(err);
            reader.abort();
        }
        reader.readAsDataURL(file);
    };

    const mapCustomerFields = [
        { label: 'คำนำหน้านาม', value: 'c_name_title' },
        { label: 'ชื่อ', value: 'c_first_name' },
        { label: 'นามสกุล', value: 'c_last_name' },
        { label: 'โทรศัพท์', value: 'c_phone' },
        { label: 'อีเมล', value: 'c_email' },
        { label: 'ที่อยู่', value: 'c_address' },
        { label: 'แขวง/ตำบล', value: 'c_tambon' },
        { label: 'เขต/อำเภอ', value: 'c_amphure' },
        { label: 'จังหวัด', value: 'c_province' },
        { label: 'รหัสไปรษณีย์', value: 'c_zipcode' },
        { label: 'ไอดีไลน์', value: 'c_line_id' },
        { label: 'อายุ', value: 'c_age' },
        { label: 'สื่อ', value: 'c_media' },
        { label: 'รายได้', value: 'c_income' },
        { label: 'อาชีพ', value: 'c_career' },
        { label: 'บริษัท', value: 'c_company' },
        { label: 'ที่อยู่บริษัท/กิจการ', value: 'c_company_address' },
        { label: 'เขตที่อยู่บริษัท/กิจการ', value: 'c_company_amphure' },
        { label: 'ชมใหม่', value: 'new_view' },
        { label: 'ชมซ้ำ', value: 'return_view' },
        { label: 'โทรเข้า', value: 'call_in' },
        { label: 'สถานะเกรดลูกค้า', value: 'c_grade' },
        { label: 'วันที่เข้าชม', value: 'view_date' },
        { label: 'สถานะเกรดจากวันเข้าชม', value: 'after_view_grade' },
    ];

    const handleMapFielsChange = (e) => {
        const { name, value } = e.target;
        setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
    }

    const handleAddImagetoImageCheckbox = (event, index, type, value) => {
        // Read image and set to options index
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const newOptions = [...settings.options];
            newOptions[index]['image'] = e.target.result;
            setSettings(prevSettings => ({ ...prevSettings, options: newOptions }));
        };
        reader.onerror = (err) => {
            console.log(err);
            reader.abort();
        }
        reader.onabort = (err) => {
            console.log(err);
            reader.abort();
        }
        reader.readAsDataURL(file);
    }


    return (
        <div className="p-4 rounded-lg ">
            <h3 className="mb-4 text-lg font-bold">Settings {element.name}</h3>

            {element.type !== 'section' && element.type !== 'sectionEnd' && element.type !== 'page' && (
                <div className='mb-4'>
                    <label className='block mb-2'>ความกว้าง</label>
                    <Slider
                        value={settings.columns || 4}
                        onChange={(e, value) => handleInputChange({
                            target: {
                                name: 'columns',
                                value: value
                            }
                        })}
                        step={1}
                        min={1}
                        max={4}
                        valueLabelDisplay="auto"
                    />
                </div>
            )}

            {['section', 'page'].includes(element.type) && (
                <>
                    <div className='mb-4'>
                        <label className='block mb-2'>สีพื้นหลัง</label>
                        <div
                            className='flex gap-4 items-center border p-2 rounded-lg'
                            style={{
                                backgroundColor: settings.backgroundColor || '#ffffff'
                            }}
                        >
                            <SketchPicker
                                color={settings.backgroundColor || '#ffffff'}
                                onChange={(color) => {
                                    handleInputChange({
                                        target: {
                                            name: 'backgroundColor',
                                            value: String(color.hex) || '#ffffff'
                                        }
                                    })

                                }}
                            />

                            <span style={{
                                color: settings.backgroundColor && settings.backgroundColor.length > 3 && settings.backgroundColor[0] === '#'
                                    ? '#' + (Number(`0x1${settings.backgroundColor.substring(1)}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()
                                    : '#ffffff'
                            }}>
                                {element.backgroundColor ? element.backgroundColor : '#ffffff'}
                            </span>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-2'>สีตัวอักษร</label>
                        <div className='flex gap-4 items-center border p-2 rounded-lg'>
                            <SketchPicker
                                color={settings.textColor || '#000000'}
                                onChange={(color) => {
                                    handleInputChange({
                                        target: {
                                            name: 'textColor',
                                            value: String(color.hex) || '#000000'
                                        }
                                    })
                                }}
                            />
                            <span
                                style={{
                                    color: settings.textColor && settings.textColor.length > 3 && settings.textColor[0] === '#'
                                        ? '#' + (Number(`0x1${settings.textColor.substring(1)}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()
                                        : '#000000'
                                }}
                            >
                                {element.textColor ? element.textColor : '#000000'}
                            </span>
                        </div>
                    </div>
                </>
            )}

            {['text', 'phone', 'email', 'number', 'textarea', 'input', 'select', 'radio', 'checkbox', 'slider', 'firstName', 'lastName', 'imageCheckbox'].includes(element.type) && (
                <>
                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <TextField
                            type="text"
                            value={settings.label || ''}
                            name="label"
                            onChange={handleInputChange}
                            label="Label"
                            fullWidth
                        />
                        <TextField
                            type="text"
                            value={settings.placeholder || ''}
                            name="placeholder"
                            onChange={handleInputChange}
                            label="Placeholder"
                            fullWidth
                        />
                    </div>

                    <div className="mb-5">
                        <FormControlLabel
                            control={<Switch />}
                            label="Required ?"
                            checked={settings.validation?.required || false}
                            name="required"
                            onChange={(e) => handleValidationChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked
                                }
                            })}
                        />
                    </div>
                </>
            )}

            {['text', 'number', 'textarea', 'input'].includes(element.type) && (
                <>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <TextField
                            label="Min Length"
                            type="number"
                            value={settings.validation?.minLength || ''}
                            name="minLength"
                            onChange={handleValidationChange}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">characters</InputAdornment>,
                            }}
                        />
                        <TextField
                            label="Max Length"
                            type="number"
                            value={settings.validation?.maxLength || ''}
                            name="maxLength"
                            onChange={handleValidationChange}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">characters</InputAdornment>,
                            }}
                        />

                    </div>
                </>
            )}

            {['select', 'checkbox', 'radio'].includes(element.type) && (
                <>
                    <h4 className='text-lg font-bold'>Options</h4>
                    <div className='p-4 border rounded-lg'>
                        {settings.options?.map((option, index) => (
                            <div className='flex gap-4 mb-2' key={index}>
                                <TextField
                                    label="Option Label"
                                    value={option.label}
                                    onChange={e => handleOptionChange(index, 'label', e.target.value)}
                                    variant='standard'
                                    fullWidth
                                />
                                <TextField
                                    label="Option Value"
                                    value={option.value}
                                    onChange={e => handleOptionChange(index, 'value', e.target.value)}
                                    variant='standard'
                                    fullWidth
                                />
                                <button
                                    onClick={() => handleRemoveOption(index)}
                                    className='btn btn-danger'
                                >
                                    <ClearOutlinedIcon />
                                </button>
                            </div>
                        ))}

                        <button
                            onClick={handleAddOption}
                            className='btn btn-primary mt-4 bg-col1 text-white py-2 px-4 rounded-lg'
                        >
                            <AddOutlinedIcon />
                            เพิ่มตัวเลือก
                        </button>
                    </div>
                </>
            )}

            {element.type === 'imageCheckbox' && (
                <>
                    <h4 className='text-lg font-bold'>Options</h4>
                    <div className='p-4 border rounded-lg'>
                        {settings.options?.map((option, index) => (
                            <div className='flex gap-4 mb-2 border rounded-lg p-2' key={index}>
                                <div className='flex items-center gap-4'>
                                    <label
                                        //onDrop={handleDropImage}
                                        style={{
                                            backgroundImage: `url(${option.image})`,
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            width: '80px',
                                            height: '80px'
                                        }}
                                        className='border border-gray-300 rounded-lg flex items-center justify-center cursor-pointer'
                                    >
                                        {!option.image && (
                                            <div className='flex items-center justify-center h-full cursor-pointer'>
                                                <p className='text-gray-400 text-center'>Upload Image</p>
                                            </div>
                                        )}
                                        <input className='hidden' type="file" onChange={
                                            (event) => handleAddImagetoImageCheckbox(event, index, 'image', event.target.value)
                                        } />
                                    </label>
                                </div>
                                <div>
                                    <TextField
                                        label="Option Label"
                                        value={option.label}
                                        onChange={e => handleOptionChange(index, 'label', e.target.value)}
                                        variant='standard'
                                        fullWidth
                                    />
                                    <TextField
                                        label="Option Value"
                                        value={option.value}
                                        onChange={e => handleOptionChange(index, 'value', e.target.value)}
                                        variant='standard'
                                        fullWidth
                                    />
                                </div>

                                <button
                                    onClick={() => handleRemoveOption(index)}
                                    className='btn btn-danger'
                                >
                                    <ClearOutlinedIcon />
                                </button>
                            </div>
                        ))}

                        <button
                            onClick={handleAddOption}
                            className='btn btn-primary mt-4 bg-col1 text-white py-2 px-4 rounded-lg'
                        >
                            <AddOutlinedIcon />
                            เพิ่มตัวเลือก
                        </button>
                    </div>
                    <div className='my-8'>
                        <h4 className='text-lg font-bold'>Option type</h4>
                        <Select
                            value={settings.optionType || 'checkbox'}
                            onChange={handleInputChange}
                            name='optionType'
                            fullWidth
                        >
                            <MenuItem value='checkbox'>Checkbox</MenuItem>
                            <MenuItem value='radio'>Radio</MenuItem>
                        </Select>
                    </div>
                </>
            )}

            {['checkbox', 'radio', 'imageCheckbox'].includes(element.type) && (
                <>
                    <h4 className='text-lg font-bold mt-4'>Columns</h4>
                    <div className='p-4 border rounded-lg'>
                        <div className='grid grid-cols-1 gap-4 mb-2'>

                            <Slider
                                value={settings.choiseColumns || 1}
                                onChange={(e, value) => handleInputChange({
                                    target: {
                                        name: 'choiseColumns',
                                        value: value
                                    }
                                })}
                                step={1}
                                min={1}
                                max={4}
                                valueLabelDisplay="auto"
                            />
                        </div>
                    </div>
                </>
            )}

            {element.type === 'title' && (
                <div className='mb-4'>
                    <TextField
                        type="text"
                        value={settings.label || ''}
                        name="label"
                        onChange={handleInputChange}
                        label="Label"
                        fullWidth
                    />
                </div>
            )}

            {element.type === 'image' && (
                <>
                    <div className='mb-4'>
                        <label className='block mb-2'>Image</label>
                        <label
                            onDrop={handleDropImage}
                            style={{
                                backgroundImage: `url(${settings.image})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '200px'
                            }}
                            className='border border-gray-300 rounded-lg flex items-center justify-center cursor-pointer'
                        >
                            {!settings.image && (
                                <div className='flex items-center justify-center h-full cursor-pointer'>
                                    <p className='text-gray-400'>Upload Image</p>
                                </div>
                            )}
                            <input className='hidden' type="file" onChange={handleAddImage} />
                        </label>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-2'>Margin</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.marginTop || 0}
                                    name="marginTop"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.marginRight || 0}
                                    name="marginRight"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.marginBottom || 0}
                                    name="marginBottom"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.marginLeft || 0}
                                    name="marginLeft"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {element.type === 'checkbox' && (
                <div className='mt-4'>
                    <label className='block mb-2'>รูปแบบการแสดงผล</label>
                    <Select
                        value={settings.displayType || 'checkbox'}
                        onChange={handleInputChange}
                        name='displayType'
                        fullWidth
                    >
                        <MenuItem value='checkbox'>กล่องติ๊กถูก</MenuItem>
                        <MenuItem value='tagcloud'>แท็กคลาวด์</MenuItem>
                    </Select>
                </div>
            )}

            {['address'].includes(element.type) && (
                <>
                    <div className="mb-5">
                        <FormControlLabel
                            control={<Switch />}
                            label="Required ?"
                            checked={settings.validation?.required || false}
                            name="required"
                            onChange={(e) => handleValidationChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked
                                }
                            })}
                        />
                    </div>
                </>
            )}

            {element.type === 'section' && (
                <>
                    <div className='mt-4'>
                        <label className='block mb-2'>สีเส้นขอบ</label>
                        <div className='flex gap-4 items-center border p-2 rounded-lg'>
                            <SketchPicker
                                color={settings.borderColor || '#000000'}
                                onChange={(color) => {
                                    handleInputChange({
                                        target: {
                                            name: 'borderColor',
                                            value: String(color.hex) || '#000000'
                                        }
                                    })
                                }}
                            />
                            <span>
                                {element.borderColor ? element.borderColor : '#000000'}
                            </span>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <label className='block mb-2'>ความหนาเส้นขอบ</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.borderTopWidth || 0}
                                    name="borderTopWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.borderRightWidth || 0}
                                    name="borderRightWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.borderBottomWidth || 0}
                                    name="borderBottomWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.borderLeftWidth || 0}
                                    name="borderLeftWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <label className='block mb-2'>ความโค้งเส้นขอบ</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.borderTopRadius || 0}
                                    name="borderTopRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.borderTopRightRadius || 0}
                                    name="borderTopRightRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.borderBottomRightRadius || 0}
                                    name="borderBottomRightRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.borderBottomLeftRadius || 0}
                                    name="borderBottomLeftRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='my-4'>
                        <label className='block mb-2'>Margin</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.marginTop || 0}
                                    name="marginTop"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.marginRight || 0}
                                    name="marginRight"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.marginBottom || 0}
                                    name="marginBottom"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.marginLeft || 0}
                                    name="marginLeft"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='my-4'>
                        <label className='block mb-2'>Padding</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.paddingTop || 0}
                                    name="paddingTop"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.paddingRight || 0}
                                    name="paddingRight"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.paddingBottom || 0}
                                    name="paddingBottom"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.paddingLeft || 0}
                                    name="paddingLeft"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {element.type === 'slider' && (
                <>
                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <TextField
                            type="number"
                            value={settings.min || ''}
                            name="min"
                            onChange={handleInputChange}
                            label="Min"
                            fullWidth
                        />
                        <TextField
                            type="number"
                            value={settings.max || ''}
                            name="max"
                            onChange={handleInputChange}
                            label="Max"
                            fullWidth
                        />
                    </div>
                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <TextField
                            type="number"
                            value={settings.step || ''}
                            name="step"
                            onChange={handleInputChange}
                            label="Step"
                            fullWidth
                        />
                        <TextField
                            type="number"
                            value={settings.defaultValue || ''}
                            name="defaultValue"
                            onChange={handleInputChange}
                            label="Default Value"
                            fullWidth
                        />
                    </div>
                </>
            )}


            {['text', 'number', 'textarea', 'input', 'select', 'radio', 'checkbox', 'slider', 'imageCheckbox'].includes(element.type) && (
                <div className='mt-4'>
                    <label className='block mb-2'>การเชื่อมต่อข้อมูล</label>
                    <Select
                        value={settings.mapFiels || ''}
                        onChange={handleMapFielsChange}
                        name='mapFiels'
                        fullWidth
                    >
                        <MenuItem value=''>ไม่เชื่อมต่อข้อมูล</MenuItem>
                        {mapCustomerFields.map((mapCustomerField, index) => (
                            <MenuItem key={index} value={mapCustomerField.value}>{mapCustomerField.label}</MenuItem>
                        ))}
                    </Select>
                </div>
            )}

            {['input', 'textarea'].includes(element.type) && (
                <>
                    <div className='my-4'>
                        <label className='block mb-2'>สีพื้นหลัง</label>
                        <div
                            className='flex gap-4 items-center border p-2 rounded-lg'
                            style={{
                                backgroundColor: settings.backgroundColor || '#ffffff'
                            }}
                        >
                            <SketchPicker
                                color={settings.backgroundColor || '#ffffff'}
                                onChange={(color) => {
                                    handleInputChange({
                                        target: {
                                            name: 'backgroundColor',
                                            value: String(color.hex) || '#ffffff'
                                        }
                                    })

                                }}
                            />

                            <span style={{
                                color: settings.backgroundColor && settings.backgroundColor.length > 3 && settings.backgroundColor[0] === '#'
                                    ? '#' + (Number(`0x1${settings.backgroundColor.substring(1)}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()
                                    : '#ffffff'
                            }}>
                                {element.backgroundColor ? element.backgroundColor : '#ffffff'}
                            </span>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='block mb-2'>สีตัวอักษร</label>
                        <div className='flex gap-4 items-center border p-2 rounded-lg'>
                            <SketchPicker
                                color={settings.textColor || '#000000'}
                                onChange={(color) => {
                                    handleInputChange({
                                        target: {
                                            name: 'textColor',
                                            value: String(color.hex) || '#000000'
                                        }
                                    })
                                }}
                            />
                            <span
                                style={{
                                    color: settings.textColor && settings.textColor.length > 3 && settings.textColor[0] === '#'
                                        ? '#' + (Number(`0x1${settings.textColor.substring(1)}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()
                                        : '#000000'
                                }}
                            >
                                {element.textColor ? element.textColor : '#000000'}
                            </span>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <label className='block mb-2'>สีเส้นขอบ</label>
                        <div className='flex gap-4 items-center border p-2 rounded-lg'>
                            <SketchPicker
                                color={settings.borderColor || '#000000'}
                                onChange={(color) => {
                                    handleInputChange({
                                        target: {
                                            name: 'borderColor',
                                            value: String(color.hex) || '#000000'
                                        }
                                    })
                                }}
                            />
                            <span>
                                {element.borderColor ? element.borderColor : '#000000'}
                            </span>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <label className='block mb-2'>ความหนาเส้นขอบ</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.borderTopWidth || 0}
                                    name="borderTopWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.borderRightWidth || 0}
                                    name="borderRightWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.borderBottomWidth || 0}
                                    name="borderBottomWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.borderLeftWidth || 0}
                                    name="borderLeftWidth"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <label className='block mb-2'>ความโค้งเส้นขอบ</label>
                        <div className='grid grid-cols-4 gap-6'>
                            <div>
                                <label>บน</label>
                                <input
                                    type="number"
                                    value={settings.borderTopRadius || 0}
                                    name="borderTopRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ขวา</label>
                                <input
                                    type="number"
                                    value={settings.borderTopRightRadius || 0}
                                    name="borderTopRightRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ล่าง</label>
                                <input
                                    type="number"
                                    value={settings.borderBottomRightRadius || 0}
                                    name="borderBottomRightRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                            <div>
                                <label>ซ้าย</label>
                                <input
                                    type="number"
                                    value={settings.borderBottomLeftRadius || 0}
                                    name="borderBottomLeftRadius"
                                    onChange={handleInputChange}
                                    className='border border-gray-300 rounded-lg p-1 w-full'
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}
export default ElementSettings;