const AstinLogo = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 800 471.4"
    style={{
      enableBackground: "new 0 0 800 471.4",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:#A2877C;}\n\t.st1{fill:#C1A293;}\n\t.st2{fill:#887267;}\n"
      }
    </style>
    <g>
      <polygon
        className="st0"
        points="222.1,409 252.9,409 252.9,417 231,417 231,425.3 250.3,425.3 250.3,433.3 231,433.3 231,441.9  253.2,441.9 253.2,449.9 222.1,449.9  "
      />
      <path
        className="st0"
        d="M286,443.9l5.3-6.4c3.7,3,7.5,5,12.2,5c3.7,0,5.9-1.5,5.9-3.8v-0.1c0-2.3-1.4-3.4-8.2-5.2 c-8.2-2.1-13.6-4.4-13.6-12.5v-0.1c0-7.4,6-12.3,14.3-12.3c6,0,11,1.9,15.2,5.2l-4.7,6.8c-3.6-2.5-7.2-4-10.6-4 c-3.5,0-5.3,1.6-5.3,3.6v0.1c0,2.7,1.8,3.6,8.8,5.4c8.3,2.2,13,5.2,13,12.3v0.1c0,8.1-6.2,12.7-15,12.7 C297.1,450.5,290.9,448.3,286,443.9"
      />
      <polygon
        className="st0"
        points="362.8,417.3 350.3,417.3 350.3,409 384.2,409 384.2,417.3 371.8,417.3 371.8,449.9 362.8,449.9  "
      />
      <path
        className="st0"
        d="M427.6,408.7h8.3l17.5,41.2H444l-3.7-9.2h-17.3l-3.7,9.2H410L427.6,408.7z M437,432.8l-5.4-13.3l-5.4,13.3H437 z"
      />
      <polygon
        className="st0"
        points="491.7,417.3 479.3,417.3 479.3,409 513.2,409 513.2,417.3 500.7,417.3 500.7,449.9 491.7,449.9  "
      />
      <polygon
        className="st0"
        points="546.8,409 577.6,409 577.6,417 555.7,417 555.7,425.3 575,425.3 575,433.3 555.7,433.3 555.7,441.9  577.9,441.9 577.9,449.9 546.8,449.9  "
      />
      <path
        className="st0"
        d="M162.8,360.1L149,333.4l-39.8-8.5l-39.8,8.5l-13.8,26.7H34.1l62.7-119.3h24.7l62.7,119.3H162.8z M109.3,257.8 L79,315.5l30.2-8.5l30.2,8.5L109.3,257.8z"
      />
      <path
        className="st0"
        d="M276.2,255.3c-24.6,0-37.5,6.3-37.5,16.1c0,5.4,3.4,9.1,10.3,11.5c12.9,4.6,32.4,3.1,56.5,6.3 c29.4,3.8,43.5,14,43.5,34c0,25-22.6,39.7-64.6,39.7c-30.1,0-53.3-6.4-70.5-20.1l10.8-14.3c19,11.7,38.3,17.1,59.9,17.1 c28.1,0,43.2-8.2,43.2-21.1c0-17-20.1-17-55-19.3c-36.5-2.5-54.8-8.2-54.8-31.1c0-22.2,21.3-36.2,59.7-36.2 c25.5,0,47,5.9,64.1,17.6l-11.9,13.4C312.2,259.8,294.9,255.3,276.2,255.3"
      />
      <polygon
        className="st0"
        points="458.6,258.8 458.6,360.1 439,360.1 439,258.8 382.4,258.8 382.4,240.8 515.2,240.8 515.2,258.8  "
      />
      <rect x={555.2} y={240.8} className="st0" width={19.6} height={119.3} />
      <polygon
        className="st0"
        points="741.6,360.1 651,262.3 651,360.1 631.4,360.1 631.4,240.8 655.7,240.8 746.2,338.7 746.2,240.8  765.9,240.8 765.9,360.1  "
      />
      <polygon
        className="st1"
        points="320,122.2 320,91 284.8,109.6 284.8,168 398,114.5 398,83.5  "
      />
      <polygon className="st1" points="398,15.1 320,55.9 320,91 398,50.1  " />
      <polygon
        className="st2"
        points="480,122.2 480,91 515.2,109.6 515.2,168 402,114.5 402,83.5  "
      />
      <polygon className="st2" points="402,15.1 480,55.9 480,91 402,50.1  " />
    </g>
  </svg>
);
export default AstinLogo;
