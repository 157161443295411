import { useState, useEffect, useCallback, useContext } from 'react'
import Layout from '../layouts/layout'
import LoadingContext from '../contexts/LoadingContext';
import Pagination from '@mui/material/Pagination';
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Button from '@mui/material/Button';
import { Tooltip, IconButton } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ExportSubmissions from '../components/ExportSubmissions';
const adminRole = JSON.parse(localStorage.getItem('admin_role'));

const Submissions = () => {

    const [submissions, setSubmissions] = useState([]);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
    const [filter, setFilter] = useState(1);
    const [answers, setAnswers] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openConvert, setOpenConvert] = useState(false);
    const [sales, setSales] = useState([]);
    const [selectedSale, setSelectedSale] = useState(null);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const fetchSubmissions = useCallback((value = 1, search = '', filter = '') => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/submissions/get/${value}?search=${search}&filter=${filter}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setSubmissions(res.data.submissions);
            setTotalPages(res.data.pagination.totalPages);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchSubmissions(page, '', filter);
    }, [page, fetchSubmissions, filter]);

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/submissions/delete/${selectedQuestionnaire}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setOpen(false);
            fetchSubmissions(page, '', filter);
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedQuestionnaire(null);
    };

    const handleOpen = (questionnaire) => {
        setSelectedQuestionnaire(questionnaire);
        setOpen(true);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/users/get/${1}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setSales(res.data.data);
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [setIsLoading]);

    const convertToOpportunity = () => {

        if (!selectedSale) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกพนักงานขาย'
            });
            return;
        }

        if (!selectedQuestionnaire) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'ไม่พบข้อมูลการตอบแบบสอบถาม'
            });
            return;
        }


        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/submissions/convert-to-op`, {
            response: selectedQuestionnaire,
            sale: selectedSale
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            fetchSubmissions(page, '', filter);
            setOpenConvert(false);
            setSelectedSale(null);
            setSelectedQuestionnaire(null);

            setAlert({
                open: true,
                type: 'success',
                msg: 'บันทึกข้อมูลเรียบร้อย'
            });
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }


    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-4'>
                        <h1 className='text-2xl font-bold'>รายการลูกค้าตอบแบบสอบถาม</h1>
                    </div>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-label="filter"
                            name="filter"
                            value={filter}
                            onChange={(e) => {
                                setFilter(parseInt(e.target.value));
                                //fetchSubmissions(page, '', parseInt(e.target.value));
                            }}

                        >
                            <FormControlLabel value={0} control={<Radio />} label="ยังไม่มอบหมาย" />
                            <FormControlLabel value={1} control={<Radio />} label="มอบหมายแล้ว" />
                        </RadioGroup>
                    </FormControl>
                    {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') && <ExportSubmissions/>}
                </div>
                <div className='table-row-warp mt-8 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <table className='table-auto w-full bg-white p-4 rounded-lg shadow'>
                        <thead>
                            <tr>
                                <th className='px-4 py-2 text-left'>วันที่</th>
                                <th className='px-4 py-2 text-left'>ชื่อลูกค้า</th>
                                <th className='px-4 py-2 text-left'>โทรศัพท์</th>
                                <th className='px-4 py-2 text-left'>แบบสอบถาม</th>
                                <th className='px-4 py-2 text-left'>พนักงานขาย</th>
                                <th className='px-4 py-2 text-right'>จัดการ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {submissions?.map((submission, index) => {
                                return (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50/50' : ''}`}>
                                        <td className='border-t border-t-gray-200 px-4 py-2'>
                                            {new Date(submission.submit_date).toLocaleDateString('th-TH', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                second: 'numeric',
                                            })}
                                        </td>
                                        <td className='border-t border-t-gray-200 px-4 py-2'>{submission.customer?.name} {submission.customer?.lastname}</td>
                                        <td className='border-t border-t-gray-200 px-4 py-2'>{submission.customer?.phone}</td>
                                        <td className='border-t border-t-gray-200 px-4 py-2'>{submission.q_title}</td>
                                        <td className='border-t border-t-gray-200 px-4 py-2'>{
                                            submission.first_name && submission.last_name ? `${submission.first_name} ${submission.last_name}` : 'ยังไม่มอบหมาย'
                                        }</td>
                                        <td className='border-t border-t-gray-200 px-4 py-2 text-right'>
                                            <div className='flex justify-end gap-2'>

                                                {!submission.first_name && !submission.last_name &&
                                                    <Tooltip title="มอบหมาย" placement="top" arrow >
                                                        <IconButton aria-label="edit" onClick={() => {
                                                            setOpenConvert(true);
                                                            setSelectedQuestionnaire(submission.response_id);
                                                        }}>
                                                            <EditNoteOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }


                                                <Tooltip title="รายละเอียด" placement="top" arrow >
                                                    <IconButton aria-label="detail" onClick={() => {
                                                        setOpenDrawer(true);
                                                        setAnswers(submission.response_json);
                                                    }}>
                                                        <RemoveRedEyeOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="ลบ" placement="top" arrow >
                                                    <IconButton aria-label="delete" onClick={() => handleOpen(submission.response_id)}>
                                                        <DeleteOutlineOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>
                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page} / {totalPages}</div>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} disabled={isLoading} />
                </div>
            </div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>ยินยันการลบข้อมูล</h2>
                    <p>ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้</p>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button className='btn btn-danger' onClick={handleDelete}>ลบ</Button>
                        <Button className='btn btn-secondary' onClick={handleClose}>ยกเลิก</Button>
                    </div>
                </div>
            </Dialog>

            <Drawer
                anchor='left'
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>รายละเอียดการตอบแบบสอบถาม</h2>
                </div>

                <div className='p-6'>
                    {answers?.map((answer, index) => {
                        let displayedAnswer = answer.answer;

                        if (typeof answer.answer === 'object' && answer.answer !== null) {
                            if (answer.q_input_type === "address") {
                                displayedAnswer = `${answer.answer.tambon.name_th}, ${answer.answer.amphure.name_th}, ${answer.answer.province.name_th}`;
                                answer.q_label = "ที่อยู่";
                            } else {
                                displayedAnswer = Object.values(answer.answer).join(', ');
                            }
                        }

                        return (
                            <div key={index} className='flex flex-col mb-6'>
                                <div className='text-sm text-gray-500'>{answer.q_label}</div>
                                <div className=''>{displayedAnswer}</div>
                            </div>
                        )
                    })}
                </div>
            </Drawer>

            <Dialog
                onClose={() => setOpenConvert(false)}
                aria-labelledby="simple-dialog-title"
                open={openConvert}
                width='md'
                fullWidth
            >
                <div className='p-6 text-gray-600 flex flex-col justify-between' style={{ minHeight: '350px' }}>
                    <div>
                        <h2 className='text-xl font-bold mb-4'>มอบหมายให้พนักงานขาย</h2>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="combo-box-demo"
                            options={sales}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelectedSale(value.id);
                                }
                            }}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                            renderInput={(params) => <TextField {...params} label="เลือกพนักงาน" />}
                        />
                    </div>
                    <div className='flex justify-end items-center gap-2 mt-8'>
                        <Button className='btn btn-primary' onClick={() => {
                            convertToOpportunity();
                        }}>บันทึก</Button>
                        <Button className='btn btn-secondary' onClick={() => {
                            setOpenConvert(false);
                            setSelectedSale(null);
                            setSelectedQuestionnaire(null);
                        }}>ยกเลิก</Button>
                    </div>
                </div>
            </Dialog>


            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default Submissions