import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function NumberOfCustomerVisits() {

    const [groupBy, setGroupBy] = useState('day');
    const [reportData, setReportData] = useState(null);
    const [staticData, setStaticData] = useState(null);
    const [isSetStaticData, setIsSetStaticData] = useState(false);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().subtract(6, 'day').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().subtract(0, 'day').format('YYYY-MM-DD')),
    });

    const formatDateInThai = date => {
        // check date 
        if (!dayjs(date).isValid()) {
            return '';
        }

        // if year month
        if (date === dayjs(date).format('YYYY-MM')) {
            return dayjs(date).locale('th').format('MMM'); // Adjust the format as needed
        } else {
            return dayjs(date).locale('th').format('DD MMM'); // Adjust the format as needed
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/customer-visits-from-survey`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        groupBy: groupBy,
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);
                if (!isSetStaticData) {
                    setStaticData(data);
                    setIsSetStaticData(true);
                }

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }
        fetchData();

    }, [filterDate, groupBy, isSetStaticData]);

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    <Select
                        value={groupBy}
                        onChange={(e) => setGroupBy(e.target.value)}
                        size='small'
                        sx={{
                            width: 200,
                            backgroundColor: '#fff',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e5e5ea',
                            }
                        }}
                    >
                        <MenuItem value={'day'}>รายวัน</MenuItem>
                        <MenuItem value={'month'}>รายเดือน</MenuItem>
                    </Select>

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            <h3 className='text-xl font-bold mb-4 text-center'>7 วันล่าสุด</h3>
                            {reportData && (
                                <Line
                                    data={{
                                        labels: staticData.current_year.map(item => formatDateInThai(item.group_date)),
                                        datasets: [{
                                            label: 'ล่าสุด',
                                            data: staticData.current_year.map(item => item.total_responses),
                                            backgroundColor: `#FF6384`,
                                            borderColor: `#FF6384`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: 'สัปดาห์ก่อน',
                                            data: staticData.r_7_days_ago.map(item => item.total_responses),
                                            backgroundColor: `#36A2EB`,
                                            borderColor: `#36A2EB`,
                                            borderWidth: 1
                                        }]
                                    }}
                                    options={{
                                        plugins: {
                                            tooltip: {
                                                mode: 'index',
                                                intersect: false,
                                                callbacks: {
                                                    title: function (tooltipItems) {
                                                        // Display the date of the current point
                                                        //let title = dayjs(staticData.current_year[tooltipItems[0].dataIndex].group_date).locale('th').format('DD MMM');
                                                        return '';
                                                    },
                                                    label: function (context) {
                                                        let label = context.dataset.label;
                                                        let value = context.raw;

                                                        if (context.datasetIndex === 1) {
                                                            // Adjust the label for the 'Previous week' dataset
                                                            label = dayjs(staticData.current_year[context.dataIndex].group_date).subtract(7, 'day').locale('th').format('DD MMM');
                                                        } else {
                                                            // Use the 'Current week' label
                                                            label = dayjs(staticData.current_year[context.dataIndex].group_date).locale('th').format('DD MMM');
                                                        }

                                                        return `${label}: ${value}`;
                                                    },
                                                    labelColor: function (context) {
                                                        return {
                                                            borderColor: 'transparent',
                                                            backgroundColor: context.dataset.borderColor, // Use borderColor for the color box in line charts
                                                        };
                                                    }
                                                }
                                            }
                                        },
                                        // ... other options
                                    }}

                                />
                            )}
                        </CardContent>
                    </Card>

                    <Card className='my-4'>
                        <CardContent>
                            {reportData && (
                                <Line
                                    data={{
                                        labels: reportData.current_year.map(item => formatDateInThai(item.group_date)),
                                        datasets: [{
                                            label: 'ล่าสุด',
                                            data: reportData.current_year.map(item => item.total_responses),
                                            backgroundColor: `#FF6384`,
                                            borderColor: `#FF6384`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: 'เดือนก่อน',
                                            data: reportData.r_1_month_ago.map(item => item.total_responses),
                                            backgroundColor: `#FFCE56`,
                                            borderColor: `#FFCE56`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: '3 เดือนก่อน',
                                            data: reportData.r_3_months_ago.map(item => item.total_responses),
                                            backgroundColor: `#4BC0C0`,
                                            borderColor: `#4BC0C0`,
                                            borderWidth: 1
                                        },
                                        {
                                            label: 'ปีก่อน',
                                            data: reportData.last_year.map(item => item.total_responses),
                                            backgroundColor: `#9966FF`,
                                            borderColor: `#9966FF`,
                                            borderWidth: 1
                                        }]
                                    }}
                                    options={{
                                        plugins: {
                                            tooltip: {
                                                mode: 'index',
                                                intersect: false,
                                                callbacks: {
                                                    title: function (tooltipItems) {
                                                        // Display the date of the current point for all datasets
                                                        //return dayjs(reportData.current_year[tooltipItems[0].dataIndex].group_date).locale('th').format('DD MMM');
                                                        return '';
                                                    },
                                                    label: function (context) {
                                                        let label = context.dataset.label;
                                                        let value = context.raw;
                                                        // Customize labels based on dataset index
                                                        switch (context.datasetIndex) {
                                                            case 1: // For 'เดือนก่อน'
                                                                label = reportData.current_year[context.dataIndex].group_date === dayjs(reportData.current_year[context.dataIndex].group_date).format('YYYY-MM')
                                                                    ? dayjs(reportData.current_year[context.dataIndex].group_date).subtract(1, 'month').locale('th').format('MMM')
                                                                    : dayjs(reportData.current_year[context.dataIndex].group_date).subtract(1, 'month').locale('th').format('DD MMM');
                                                                break;
                                                            case 2: // For '3 เดือนก่อน'
                                                                //dayjs(reportData.current_year[context.dataIndex].group_date).subtract(3, 'month').locale('th').format('DD MMM');
                                                                label = reportData.current_year[context.dataIndex].group_date === dayjs(reportData.current_year[context.dataIndex].group_date).format('YYYY-MM')
                                                                    ? dayjs(reportData.current_year[context.dataIndex].group_date).subtract(3, 'month').locale('th').format('MMM')
                                                                    : dayjs(reportData.current_year[context.dataIndex].group_date).subtract(3, 'month').locale('th').format('DD MMM');
                                                                break;
                                                            case 3: // For 'ปีก่อน'
                                                                //label = dayjs(reportData.current_year[context.dataIndex].group_date).subtract(1, 'year').locale('th').format('DD MMM');
                                                                label = reportData.current_year[context.dataIndex].group_date === dayjs(reportData.current_year[context.dataIndex].group_date).format('YYYY-MM')
                                                                    ? dayjs(reportData.current_year[context.dataIndex].group_date).subtract(1, 'year').locale('th').format('MMM')
                                                                    : dayjs(reportData.current_year[context.dataIndex].group_date).subtract(1, 'year').locale('th').format('DD MMM');
                                                                break;
                                                            default:
                                                                // 'ล่าสุด' uses the current date
                                                                //label = dayjs(reportData.current_year[context.dataIndex].group_date).locale('th').format('DD MMM');
                                                                label = reportData.current_year[context.dataIndex].group_date === dayjs(reportData.current_year[context.dataIndex].group_date).format('YYYY-MM')
                                                                    ? dayjs(reportData.current_year[context.dataIndex].group_date).locale('th').format('MMM')
                                                                    : dayjs(reportData.current_year[context.dataIndex].group_date).locale('th').format('DD MMM');
                                                        }

                                                        return `${label}: ${value}`;
                                                    },
                                                    labelColor: function (context) {
                                                        return {
                                                            borderColor: 'transparent',
                                                            backgroundColor: context.dataset.borderColor, // Use borderColor for the color box
                                                        };
                                                    }
                                                }
                                            }
                                        },
                                        // ... other options
                                    }}

                                />
                            )}
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <table className='w-full border-collapse border border-gray-300'>
                                <thead>
                                    <tr>
                                        <th className='border border-gray-200 px-4 py-2 text-left'>วัน / เดือน</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>ล่าสุด</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>เดือนก่อน</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>3 เดือนก่อน</th>
                                        <th className='border border-gray-200 px-4 py-2 text-right'>ปีก่อน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData && reportData.current_year.map((row, index) => (
                                        <tr key={row.group_date} className='hover:bg-slate-50'>
                                            <td className='border border-gray-200 px-4 py-2'>{formatDateInThai(row.group_date)}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{row.total_responses}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago[index]?.total_responses || "0"}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_months_ago[index]?.total_responses || "0"}</td>
                                            <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year[index]?.total_responses || "0"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </div>
            </div>


        </Layout >
    );
}
