import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../layouts/layout";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../components/predefinedBottomRanges';
import OpportunityList from '../components/OpportunityList';
import { Link } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function Dashboard() {

    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/employee-report`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        fetchData();

    }, [filterDate]);

    const salesByEmployeeChart = reportData && {
        labels: reportData.salesByEmployee.map(s => s.SaleFirstName + ' ' + s.SaleLastName),
        datasets: [{
            label: 'ยอดขาย',
            data: reportData.salesByEmployee.map(s => parseFloat(s.TotalSaleValue)),
            backgroundColor: '#887267',
            borderColor: '#887267',
            borderWidth: 1
        }]
    };

    const customersByEmployeeChart = reportData && {
        labels: reportData.customersByEmployee.map(c => c.SaleFirstName + ' ' + c.SaleLastName),
        datasets: [{
            label: 'จำนวนลูกค้า',
            data: reportData.customersByEmployee.map(c => c.CustomerCount),
            backgroundColor: '#d6beb1',
            borderColor: '#d6beb1',
            borderWidth: 1
        }]
    };

    const conversionRateChartData = reportData && {
        labels: ['Sales Conversion Rate', 'Transfer Conversion Rate'],
        datasets: [
            {
                label: 'Conversion Rates',
                data: [
                    reportData?.salesConversionRate?.percentage || 0,
                    reportData?.transferConversionRate?.percentage || 0
                ],
                backgroundColor: ['#887267', '#d6beb1'],
                borderColor: ['#887267', '#d6beb1'],
                borderWidth: 1
            }
        ]
    };

    /*
    const opportunityStatusColors = {
        "New": "#FFD700",
        "Qualified": "#32CD32",
        "Needs Analysis": "#1E90FF",
        "Proposal/Price Quote": "#FFA500",
        "Negotiation": "#FF6347",
        "Verbal Commitment": "#FFDAB9",
        "Closed – Won": "#008000",
        "Closed – Lost": "#FF0000",
        "On Hold": "#D3D3D3",
        "Not Qualified": "#8B0000",
        "Deferred": "#C71585",
        "Transferred": "#20B2AA",
        "Reserve": "#4B0082",
    };

    const allOpportunityStatusReport = reportData && {
        labels: Object.keys(reportData.allstatus),
        datasets: [
            {
                label: 'จำนวน',
                data: Object.keys(reportData.allstatus).map(s => reportData.allstatus[s].count),//Or sum
                backgroundColor: Object.keys(reportData.allstatus).map(s => opportunityStatusColors[s]),
                borderColor: Object.keys(reportData.allstatus).map(s => opportunityStatusColors[s]),
            }, {
                label: 'ยอดขาย',
                data: Object.keys(reportData.allstatus).map(s => reportData.allstatus[s].sum),
                backgroundColor: Object.keys(reportData.allstatus).map(s => opportunityStatusColors[s]),
                borderColor: Object.keys(reportData.allstatus).map(s => opportunityStatusColors[s]),
            }
        ],
    }
    */

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Dashboard</h1>
                <div className='flex justify-end gap-4'>
                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />
                </div>
            </div>

            <div className="grid grid-cols-12 gap-7 pb-7">
                <div className="mt-6 col-span-6">
                    <div className="">
                        <Card variant="outlined">
                            <CardContent>
                                <h3 className='text-lg font-bold text-center mb-4'>ยอดขาย</h3>
                                {reportData && <Bar data={salesByEmployeeChart} />}
                                <table className='table-auto w-full mt-6'>
                                    <thead>
                                        <tr className='border-t border-t-gray-400 hover:bg-gray-50/50'>
                                            <th className='px-2 py-1 text-left'>พนักงานขาย</th>
                                            <th className='px-2 py-1 text-right'>ยอดขาย</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reportData && reportData.salesByEmployee.map(sale => (
                                                <tr key={sale.SaleFirstName} className='border-t border-t-gray-300 hover:bg-gray-50/50'>
                                                    <td className='px-2 py-1'>{sale.SaleFirstName} {sale.SaleLastName}</td>
                                                    <td className='px-2 py-1 text-right'>{sale.TotalSaleValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>
                    </div>
                </div>

                <div className="mt-6 col-span-6">
                    <div className="">
                        <Card variant="outlined">
                            <CardContent>
                                <h3 className='text-lg font-bold text-center mb-4'>ยอดรับลูกค้า</h3>
                                {reportData && <Bar data={customersByEmployeeChart} />}
                                <table className='table-auto w-full mt-6'>
                                    <thead>
                                        <tr className='border-t border-t-gray-400 hover:bg-gray-50/50'>
                                            <th className='px-2 py-1 text-left'>พนักงานขาย</th>
                                            <th className='px-2 py-1 text-right'>ยอดรับลูกค้า</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reportData && reportData.customersByEmployee.map(customer => (
                                                <tr key={customer.SaleFirstName} className='border-t border-t-gray-300 hover:bg-gray-50/50'>
                                                    <td className='px-2 py-1'>{customer.SaleFirstName} {customer.SaleLastName}</td>
                                                    <td className='px-2 py-1 text-right'>{customer.CustomerCount}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-7 pb-8">
                <div className="col-span-6">
                    <Card variant="outlined">
                        <CardContent>
                            <h3 className='text-lg font-bold text-center mb-4'>Conversion Rates</h3>
                            {reportData && <Bar data={conversionRateChartData} />}
                            {reportData && (
                                <table className='table-auto w-full mt-6'>
                                    <thead>
                                        <tr className='border-t border-t-gray-400 hover:bg-gray-50/50'>
                                            <th className='px-2 py-1 text-left'>Metric</th>
                                            <th className='px-2 py-1 text-center'>ปิดการขาย/โอน</th>
                                            <th className='px-2 py-1 text-center'>ลูกค้าที่รับ</th>
                                            <th className='px-2 py-1 text-right'>Percentage (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-t border-t-gray-300 hover:bg-gray-50/50'>
                                            <td className='px-2 py-1'>ยอดจอง</td>
                                            <td className='px-2 py-1 text-center'>{reportData.salesConversionRate.reserveCount}</td>
                                            <td className='px-2 py-1 text-center'>{reportData.salesConversionRate.opportunityCount}</td>
                                            <td className='px-2 py-1 text-right'>{reportData.salesConversionRate.percentage.toFixed(2)}</td>
                                        </tr>
                                        <tr className='border-t border-t-gray-300 hover:bg-gray-50/50'>
                                            <td className='px-2 py-1'>ยอดโอน</td>
                                            <td className='px-2 py-1 text-center'>{reportData.transferConversionRate.transferCount}</td>
                                            <td className='px-2 py-1 text-center'>{reportData.transferConversionRate.opportunityCount}</td>
                                            <td className='px-2 py-1 text-right'>{reportData.transferConversionRate.percentage.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </CardContent>
                    </Card>
                </div>
                <div className='col-span-6 max-h-full'>
                    <Card variant="outlined">
                        <CardContent>
                            <h3 className='text-lg font-bold text-center mb-4'>Activities</h3>
                            {reportData &&
                                <div className='relative overflow-x-auto' style={{ height: '45vh' }}>
                                    <OpportunityList data={reportData.activities} />
                                </div>
                            }
                            <div className='flex justify-center pt-4'>
                                <Link to='/activities' className='text-sm'>ดูทั้งหมด</Link>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Layout>
    );
}