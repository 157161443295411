import { NavLink } from 'react-router-dom';

const ReportSideBar = () => {
    return (
        <div className="col-span-3 sticky top-0 self-start">
            <ul className='mt-8 pr-8 report-side-bar'>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/customer-visits-from-survey">
                        ข้อมูลลูกค้าเข้าชมจากแบบสอบถาม
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/number-of-customer-visits">
                        จำนวนลูกค้าเข้าชม
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/marketing-expenses-vs-kpi">
                        ค่าใช้จ่ายการตลาดเทียบ KPI
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/marketing-expenses-by-category">
                        สรุปค่าใช้จ่ายการตลาดแยกหมวดหมู่
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/marketing-expenses-by-media">
                        ค่าใช้จ่ายการตลาด (แยกสื่อ) : ลูกค้าเข้าชม
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/marketing-expenses-by-media-for-bookings">
                        ค่าใช้จ่ายการตลาด (แยกสื่อ) : ลูกค้าจอง
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/marketing-expenses-by-media-for-transfers">
                        ค่าใช้จ่ายการตลาด (แยกสื่อ) : ลูกค้าโอนฯ
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/online-vs-offline-expenses-report">
                        ค่าใช้จ่ายออนไลน์ : ออฟไลน์
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/sales-vs-target-report">
                        ยอดขาย : เป้าขาย
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/transfer-vs-target-report">
                        ยอดโอนฯ : เป้าโอนฯ
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/cancellation-vs-target-report">
                        ยอดยกเลิก : เป้ายกเลิก
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/media-source-report">
                        Top 3 สื่อที่ลูกค้าเข้าชม
                    </NavLink>
                </li>
                <li className='py-2 border-b'>
                    <NavLink to="/reports/media-source-report-op">
                        Top 3 สื่อที่ลูกค้าจอง
                    </NavLink>
                </li>

                <li className='py-2 border-b'>
                    <NavLink to="/reports/summary-report">
                        รายงานสรุป
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}

export default ReportSideBar;