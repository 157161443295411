import { useState, useEffect } from 'react'
import Layout from '../../layouts/layout'
import { Link } from 'react-router-dom'
import Pagination from '@mui/material/Pagination';
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const Questionnaires = () => {

    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/${page}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
            params: {
                page: page
            }
        }).then(res => {
            setQuestionnaires(res.data.data);
            setTotalPages(res.data.allPage);
            setLoading(false);
        }).catch(err => console.log(err));
    }, [page]);

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/delete/${selectedQuestionnaire}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setOpen(false);
            setQuestionnaires(questionnaires.filter(questionnaire => questionnaire.questionnaire_id !== selectedQuestionnaire));
        }).catch(err => console.log(err));
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (questionnaire) => {
        setSelectedQuestionnaire(questionnaire);
        setOpen(true);
    };

    return (
        <Layout mainClass={`bg-slate-50`}>
            <div className='min-h-screen'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-4'>
                        <h1 className='text-2xl font-bold mb-6'>รายการแบบสอบถาม</h1>
                    </div>

                    <Link to='/questionnaires/addnew' className='btn btn-primary bg-col1 text-white px-4 py-2 rounded shadow'>+ เพิ่มแบบสอบถาม</Link>
                </div>
                <div className='mt-4 overflow-x-auto'>
                    <table className='table-auto w-full bg-white p-4 rounded-lg shadow'>
                        <thead>
                            <tr>
                                <th className='px-4 py-2 text-left'>ชื่อแบบสอบถาม</th>
                                <th className='px-4 py-2 text-right'>จัดการ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questionnaires.map((questionnaire, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='border-t border-t-gray-200 px-4 py-4'>{questionnaire.q_title}</td>
                                        <td className='border-t border-t-gray-200 px-4 py-4 text-right'>
                                            <a
                                                href={`${process.env.REACT_APP_FRONTEND_URL}/questionnaire/${questionnaire.q_code}`}
                                                className='btn btn-primary w-10 h-10 mr-6'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <OpenInNewOutlinedIcon />
                                            </a>
                                            <Link
                                                to={`/questionnaires/update/${questionnaire.questionnaire_id}`}
                                                className='btn btn-primary w-10 h-10 mr-3'
                                            >
                                                <EditOutlinedIcon />
                                            </Link>
                                            <button
                                                className='btn btn-danger w-10 h-10'
                                                onClick={() => handleOpen(questionnaire.questionnaire_id)}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className='flex justify-between items-center mt-4 px-4'>
                        <div className='text-sm text-gray-600'>หน้าที่ {page} / {totalPages} หน้า</div>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} disabled={loading} />
                    </div>
                </div>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>ยินยันการลบแบบสอบถาม</h2>
                    <p>ข้อมูลแบบสอบถามนี้จะถูกลบ และไม่สามารถกู้คืนได้</p>
                    <div className='flex justify-end items-center gap-4 mt-6'>
                        <button className='btn btn-danger mr-2' onClick={handleDelete}>ลบ</button>
                        <button className='btn btn-secondary' onClick={handleClose}>ยกเลิก</button>
                    </div>
                </div>
            </Dialog>
        </Layout>
    )
}
export default Questionnaires