import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useState } from 'react';
import FormElement from '../FormBuilder/FormElement';
import FormPreview from '../FormBuilder/FormPreview';
import ElementSettings from '../FormBuilder/ElementSettings';
import TitleIcon from '@mui/icons-material/TitleOutlined';
import InputIcon from '@mui/icons-material/InputOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SliderIcon from '@mui/icons-material/TuneOutlined';
import TextFieldsIcon from '@mui/icons-material/TextFieldsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
//import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';

const FormBuilder = ({ elements, setElements, selectedElement, setSelectedElement }) => {


    const [elementCounter, setElementCounter] = useState(0);

    const addElement = (element) => {
        setElements(prevElements => [...prevElements, { ...element, id: `${Date.now()}-${elementCounter}` }]);
        setElementCounter(prevCounter => prevCounter + 1);
    };

    const handleElementsReorder = (updatedElements) => {
        //console.log("Updated elements:", updatedElements);
        setElements(updatedElements);
    };

    const updateElementSettings = (updatedSettings) => {
        const updatedElements = [...elements];
        const index = updatedElements.findIndex(element => element.id === selectedElement.id);
        updatedElements[index] = { ...updatedElements[index], ...updatedSettings };
        setElements(updatedElements);
        setSelectedElement(updatedElements[index]);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="grid grid-cols-6 gap-4 items-start">
                <div className='border p-4 rounded-lg grid grid-cols-1 gap-4 bg-white sticky top-16 self-start overflow-x-auto ' style={{maxHeight: '80vh'}}>
                    <h2>Layout</h2>
                    <FormElement type="page" name="Page" icon={<NoteAddOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="section" name="Section" icon={<ViewAgendaOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="sectionEnd" name="Section End" icon={<ViewAgendaOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="title" name="Title" icon={<TitleIcon />} onDragEnd={addElement} />
                    <h2 className='mt-4'>Input</h2>
                    <FormElement type="input" name="Text Input" icon={<InputIcon />} onDragEnd={addElement} />
                    <FormElement type="number" name="Number Input" icon={<LooksOneOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="firstName" name="First Name" icon={<Person2OutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="lastName" name="Last Name" icon={<Person2OutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="phone" name="Phone" icon={<LocalPhoneOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="email" name="Email" icon={<EmailOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="textarea" name="Text Area" icon={<TextFieldsIcon />} onDragEnd={addElement} />
                    <h2 className='mt-4'>Choice</h2>
                    <FormElement type="checkbox" name="Checkbox" icon={<CheckBoxOutlineBlankIcon />} onDragEnd={addElement} />
                    <FormElement type="radio" name="Radio Button" icon={<RadioButtonCheckedIcon />} onDragEnd={addElement} />
                    <FormElement type="select" name="Select Dropdown" icon={<ListOutlinedIcon />} onDragEnd={addElement} />
                    <FormElement type="slider" name="Slider" icon={<SliderIcon />} onDragEnd={addElement} />
                    <FormElement type="imageCheckbox" name="Image checkbox" icon={<SwitchAccountOutlinedIcon />} onDragEnd={addElement} />
                    <h2 className='mt-4'>Address</h2>
                    <FormElement type="address" name="Address" icon={<HomeOutlinedIcon />} onDragEnd={addElement} />
                    <h2 className='mt-4'>Media</h2>
                    <FormElement type="image" name="Image" icon={<ImageOutlinedIcon />} onDragEnd={addElement} />
                    {/*<h2 className='mt-4'>Submit</h2>
                    <FormElement type="submit" name="Submit" icon={<SendOutlinedIcon />} onDragEnd={addElement} />*/}
                </div>
                <div className='col-span-3 bg-white mb-20'>
                    <FormPreview
                        elements={elements}
                        selectedElement={selectedElement}
                        onSelect={setSelectedElement}
                        onUpdateElements={handleElementsReorder}
                    />
                </div>
                <div className='col-span-2 bg-white sticky top-16 self-start overflow-y-auto overflow-x-hidden h-full' style={{maxHeight: '90vh'}}>
                    {selectedElement &&
                        <ElementSettings
                            key={selectedElement.id}
                            element={selectedElement}
                            onUpdate={updateElementSettings}
                        />
                    }
                </div>
            </div>
        </DndProvider>
    );
}

export default FormBuilder;
