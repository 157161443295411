import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function MarketingExpensesByMediaForBookings() {
    const [questionnaires, setQuestionnaires] = useState([]);
    const [filterQuestion, setFilterQuestion] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            },
        }).then(res => {
            setQuestionnaires(res.data.data);
            setFilterQuestion(res.data.data[0].questionnaire_id);
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/marketing-expense-sby-media-for-bookings`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        groupBy: 'day',
                        compare: true,
                        questionnaireId: filterQuestion,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        if (questionnaires.length > 0) {
            fetchData();
        }
    }, [filterDate, filterQuestion, questionnaires]);

    const handleQuestionChange = (event) => {
        setFilterQuestion(event.target.value);
    };

    const addCommars = (num) => {
        if (num === null) {
            return "0.00";
        }
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(num)
    }

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    {questionnaires.length > 0 &&
                        <Select
                            value={filterQuestion}
                            onChange={handleQuestionChange}
                            size='small'
                            sx={{
                                backgroundColor: '#fff',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e5e5ea',
                                }
                            }}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.questionnaire_id} value={questionnaire.questionnaire_id}>
                                    {questionnaire.q_title}
                                </MenuItem>
                            ))}
                        </Select>
                    }


                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            {reportData?.current_year && (
                                <Bar
                                    data={{
                                        labels: reportData.current_year?.map(entry => entry.option_label),
                                        datasets: [
                                            {
                                                label: 'ล่าสุด',
                                                data: reportData.current_year?.map(entry => entry.expenses_per_visit),
                                                backgroundColor: `#FF6384`,
                                                borderColor: `#FF6384`,
                                                borderWidth: 1
                                            },
                                            {
                                                label: 'เดือนก่อน',
                                                data: reportData.r_1_month_ago?.map(entry => entry.expenses_per_visit),
                                                backgroundColor: `#36A2EB`,
                                                borderColor: `#36A2EB`,
                                                borderWidth: 1
                                            },
                                            {
                                                label: '2 เดือนก่อน',
                                                data: reportData.r_2_month_ago?.map(entry => entry.expenses_per_visit),
                                                backgroundColor: `#FFCE56`,
                                                borderColor: `#FFCE56`,
                                                borderWidth: 1
                                            },
                                            {
                                                label: '3 เดือนก่อน',
                                                data: reportData.r_3_month_ago?.map(entry => entry.expenses_per_visit),
                                                backgroundColor: `#4BC0C0`,
                                                borderColor: `#4BC0C0`,
                                                borderWidth: 1
                                            },
                                            {
                                                label: '6 เดือนก่อน',
                                                data: reportData.r_6_month_ago?.map(entry => entry.expenses_per_visit),
                                                backgroundColor: `#FF6B6B`,
                                                borderColor: `#FF6B6B`,
                                                borderWidth: 1
                                            },
                                            {
                                                label: 'ปีก่อน',
                                                data: reportData.last_year?.map(entry => entry.expenses_per_visit),
                                                backgroundColor: `#9966FF`,
                                                borderColor: `#9966FF`,
                                                borderWidth: 1
                                            }
                                        ]
                                    }}
                                />
                            )}
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <div className='overflow-x-auto'>
                                <table className='w-full border-collapse border border-gray-300'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className='border border-gray-200 px-4 py-2 text-left'>สื่อ</th>
                                            <th colSpan={3} className='border border-gray-200 px-4 py-2 '>ล่าสุด</th>
                                            <th colSpan={3} className='border border-gray-200 px-4 py-2 '>เดือนก่อน</th>
                                            <th colSpan={3} className='border border-gray-200 px-4 py-2 '>2 เดือนก่อน</th>
                                            <th colSpan={3} className='border border-gray-200 px-4 py-2 '>3 เดือนก่อน</th>
                                            <th colSpan={3} className='border border-gray-200 px-4 py-2 '>6 เดือนก่อน</th>
                                            <th colSpan={3} className='border border-gray-200 px-4 py-2 '>ปีก่อน</th>
                                        </tr>
                                        <tr>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ค่าใช้จ่าย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ต่อลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ค่าใช้จ่าย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ต่อลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ค่าใช้จ่าย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ต่อลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ค่าใช้จ่าย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ต่อลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ค่าใช้จ่าย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ต่อลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ค่าใช้จ่าย</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ลูกค้า</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ต่อลูกค้า</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData?.current_year && reportData.current_year?.map((row, index) => (
                                            <tr key={index}>
                                                <td className='border border-gray-200 px-4 py-2'>{row.option_label}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(row.total_expenses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{row.visit_count}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(row.expenses_per_visit)}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago && reportData.r_1_month_ago[index] ? addCommars(reportData.r_1_month_ago[index]?.total_expenses || "0.00") : "0.00"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago && reportData.r_1_month_ago[index] ? reportData.r_1_month_ago[index]?.visit_count || "0" : "0"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_1_month_ago && reportData.r_1_month_ago[index] ? addCommars(reportData.r_1_month_ago[index]?.expenses_per_visit || "0.00") : "0.00"}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_2_month_ago && reportData.r_2_month_ago[index] ? addCommars(reportData.r_2_month_ago[index]?.total_expenses || "0.00") : "0.00"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_2_month_ago && reportData.r_2_month_ago[index] ? reportData.r_2_month_ago[index]?.visit_count || "0" : "0"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_2_month_ago && reportData.r_2_month_ago[index] ? addCommars(reportData.r_2_month_ago[index]?.expenses_per_visit || "0.00") : "0.00"}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_month_ago && reportData.r_3_month_ago[index] ? addCommars(reportData.r_3_month_ago[index]?.total_expenses || "0.00") : "0.00"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_month_ago && reportData.r_3_month_ago[index] ? reportData.r_3_month_ago[index]?.visit_count || "0" : "0"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_3_month_ago && reportData.r_3_month_ago[index] ? addCommars(reportData.r_3_month_ago[index]?.expenses_per_visit || "0.00") : "0.00"}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_6_month_ago && reportData.r_6_month_ago[index] ? addCommars(reportData.r_6_month_ago[index]?.total_expenses || "0.00") : "0.00"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_6_month_ago && reportData.r_6_month_ago[index] ? reportData.r_6_month_ago[index]?.visit_count || "0" : "0"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.r_6_month_ago && reportData.r_6_month_ago[index] ? addCommars(reportData.r_6_month_ago[index]?.expenses_per_visit || "0.00") : "0.00"}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year && reportData.last_year[index] ? addCommars(reportData.last_year[index]?.total_expenses || "0.00") : "0.00"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year && reportData.last_year[index] ? reportData.last_year[index]?.visit_count || "0" : "0"}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{reportData.last_year && reportData.last_year[index] ? addCommars(reportData.last_year[index]?.expenses_per_visit || "0.00") : "0.00"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Layout >
    );
}
