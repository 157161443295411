import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from '../../layouts/layout';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Customers = () => {

    const customerDetail = [
        { field: "id", label: null, type: "hidden", value: "" },
        { field: "c_name_title", label: "คำนำหน้า", type: "select", value: "", options: [{ value: "นาย", label: "นาย" }, { value: "นาง", label: "นาง" }, { value: "นางสาว", label: "นางสาว" }] },
        { field: "c_first_name", label: "ชื่อ", type: "text", value: "" },
        { field: "c_Last_name", label: "นามสกุล", type: "text", value: "" },
        { field: "c_phone", label: "หมายเลขโทรศัพท์", type: "text", value: "" },
        { field: "c_email", label: "อีเมล", type: "text", value: "" },
        { field: "c_address", label: "ที่อยู่", type: "text", value: "" },
        { field: "c_tambon", label: "ตำบล/แขวง", type: "text", value: "" },
        { field: "c_amphure", label: "อำเภอ/เขต", type: "text", value: "" },
        { field: "c_province", label: "จังหวัด", type: "text", value: "" },
        { field: "c_zipcode", label: "รหัสไปรษณีย์", type: "text", value: "" },
        { field: "c_line_id", label: "ไอดีไลน์", type: "text", value: "" },
        { field: "c_age", label: "อายุ", type: "text", value: "" },
        { field: "c_media", label: "สื่อ", type: "text", value: "" },
        { field: "c_income", label: "รายได้", type: "text", value: "" },
        { field: "c_career", label: "อาชีพ", type: "text", value: "" },
        { field: "c_company", label: "บริษัท", type: "text", value: "" },
        { field: "c_company_address", label: "ที่ตั้งบริษัท", type: "text", value: "" },
        { field: "c_company_amphure", label: "เขตบริษัท", type: "text", value: "" },
        { field: "c_grade", label: "เกรดลูกค้า", type: "text", value: "" },
        { field: "c_owner", label: "เจ้าของ", type: "owner", value: "" },
    ];
    const [open, setOpen] = useState(false);
    const [sales, setSales] = useState([]);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [userDetail, setUserDetail] = useState({
        id: '',
        c_name_title: '',
        c_first_name: '',
        c_Last_name: '',
        c_phone: '',
        c_email: '',
        c_address: '',
        c_tambon: '',
        c_amphure: '',
        c_province: '',
        c_zipcode: '',
        c_line_id: '',
        c_age: '',
        c_media: '',
        c_income: '',
        c_career: '',
        c_company: '',
        c_company_address: '',
        c_company_amphure: '',
        c_grade: '',
        c_owner: null,
    });
    const resetUserDetail = () => {
        setUserDetail({
            id: '',
            c_name_title: '',
            c_first_name: '',
            c_Last_name: '',
            c_phone: '',
            c_email: '',
            c_address: '',
            c_tambon: '',
            c_amphure: '',
            c_province: '',
            c_zipcode: '',
            c_line_id: '',
            c_age: '',
            c_media: '',
            c_income: '',
            c_career: '',
            c_company: '',
            c_company_address: '',
            c_company_amphure: '',
            c_grade: '',
            c_owner: null,
        });
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/users/get/${1}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setSales(res.data.data);
        }).catch(err => console.log(err));
    }, []);

    const fetchcontents = useCallback((value = 1, search = null) => {
        setIsLoading(true)
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/get/${value}`;
        if (search) {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/get/${value}?search=${search}`;
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.customers);
                setIsLoading(false);
                setAllPage(response.data.totalPage);
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const validateInput = () => {
        if (userDetail.c_first_name === '' || userDetail.c_Last_name === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกชื่อ - นามสกุล'
            });
            return false;
        }

        if (userDetail.c_phone === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกหมายเลขโทรศัพท์'
            });
            return false;
        }
        return true;
    }

    const handleAddnewOrUpdate = (addOrUpdate) => {
        console.log(userDetail);
        if (!validateInput()) {
            return;
        }
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/addnew`;
        let method = 'post';
        if (addOrUpdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/update/${userDetail?.id}`;
            method = 'put';
        }
        setIsLoading(true);

        axios({
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: JSON.stringify(userDetail)
        }).then(function (response) {
            setIsLoading(false);
            fetchcontents();
            setOpenAddDialog(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'บันทึกข้อมูลสำเร็จ'
            });
            resetUserDetail();
        }).catch(function (err) {
            setIsLoading(false);

            if (err.response && err.response.status === 401) {
                localStorage.removeItem('admin_token');
                localStorage.removeItem('admin_role');
                window.location.href = '/login';
                return;
            }

            let errorMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาติดต่อผู้ดูแลระบบ';
            if (err.response && err.response.data.error) {
                const errors = err.response.data.error;
                const firstErrorKey = Object.keys(errors)[0];
                errorMessage = errors[firstErrorKey];
            }

            setAlert({
                open: true,
                type: 'error',
                msg: errorMessage
            });
        });
    }

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchcontents(1, searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        if (value === '') {
            fetchcontents();
            return;
        }
        debouncedFetchContents(value);
    }

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/customers/delete/${userDetail.id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setOpen(false);
            fetchcontents();
            setAlert({
                open: true,
                type: 'success',
                msg: 'ลบข้อมูลสำเร็จ'
            });
            resetUserDetail();
        }).catch(err => console.log(err));
    }

    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='flex gap-4 items-center'>
                        <h1 className='text-2xl font-bold hidden lg:block'>ลูกค้า</h1>
                        <input
                            type='search'
                            className='border border-gray-300 rounded-md p-2 outline-none'
                            placeholder='ค้นหา'
                            onChange={handleDebonceSearch}
                        />
                    </div>
                    <div className='flex gap-4'>
                        <Button variant="contained" onClick={() => setOpenAddDialog(true)}>+ เพิ่มลูกค้า</Button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-3/12 font-bold'>ชื่อ</div>
                            <div className='w-3/12 font-bold'>โทรศัพท์</div>
                            <div className='w-3/12 font-bold'>เจ้าของ</div>
                            <div className='w-3/12 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-3/12'>{content.c_first_name} {content.c_Last_name}</div>
                                <div className='w-3/12'>{content.c_phone}</div>
                                <div className='w-3/12'> {content.first_name ? content.first_name : 'ยังไม่ได้มอบหมาย'}</div>
                                <div className='w-3/12 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" onClick={() => {
                                            setUserDetail(content);
                                            setOpenAddDialog(true);
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" onClick={() => {
                                            setUserDetail(content);
                                            setOpen(true);
                                        }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Drawer
                open={openAddDialog}
                onClose={() => { setOpenAddDialog(false); resetUserDetail(); }}
            >
                <DialogTitle>
                    {userDetail.id ? 'แก้ไขลูกค้า' : 'เพิ่มลูกค้า'}
                    <IconButton
                        aria-label="close"
                        onClick={() => { setOpenAddDialog(false); resetUserDetail(); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='flex flex-col gap-4 w-96 max-w-full' >

                        {customerDetail.map((detail, index) => {
                            if (detail.type === 'text') {
                                return (
                                    <div key={index} className='flex flex-col gap-2'>
                                        <label className='font-bold'>{detail.label}</label>
                                        <input
                                            type='text'
                                            className='border border-gray-300 rounded-md p-2 outline-none'
                                            value={userDetail[detail.field] || ''}
                                            onChange={(e) => setUserDetail({ ...userDetail, [detail.field]: e.target.value })}
                                            placeholder={detail.label}
                                        />
                                    </div>
                                );
                            }
                            if (detail.type === 'select') {
                                return (
                                    <div key={index} className='flex flex-col gap-2'>
                                        <label className='font-bold'>{detail.label}</label>
                                        <select
                                            className='border border-gray-300 rounded-md p-2 outline-none'
                                            value={userDetail[detail.field] || ''}
                                            onChange={(e) => setUserDetail({ ...userDetail, [detail.field]: e.target.value })}
                                            placeholder={detail.label}
                                        >
                                            {detail.options.map((option, index) => (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                );
                            }
                            if (detail.type === 'owner') {
                                return (
                                    <div key={index} className='flex flex-col gap-2'>
                                        <label className='font-bold'>{detail.label}</label>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={sales}
                                            sx={{ width: '100%' }}
                                            value={userDetail[detail.field] ? sales.find(sale => sale.id === userDetail[detail.field]) : null}
                                            onChange={(event, newValue) => setUserDetail({ ...userDetail, [detail.field]: newValue ? newValue.id : null })}
                                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                            renderInput={(params) => <TextField {...params} label="เจ้าของ" />}
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        fullWidth
                        variant='outlined'
                        onClick={() => { setOpenAddDialog(false); resetUserDetail(); }}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        fullWidth
                        variant='contained'
                        disabled={isLoading}
                        onClick={() => {
                            if (!userDetail.id) {
                                handleAddnewOrUpdate('add');
                            } else {
                                handleAddnewOrUpdate('update');
                            }
                        }}
                    >
                        บันทึก
                    </Button>
                </DialogActions>
            </Drawer>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>ยินยันการลบข้อมูล</h2>
                    <p>ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้</p>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                                resetUserDetail();
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button className='btn btn-danger' onClick={handleDelete}>ลบ</Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}

export default Customers;