import { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import Layout from '../../layouts/layout';
import Button from '@mui/material/Button';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const UpdateQuestionnaires = () => {

    const location = useLocation();
    const [formTitle, setFormTitle] = useState('');
    const [elements, setElements] = useState([]);
    const [formSlug, setFormSlug] = useState('');
    const [selectedElement, setSelectedElement] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(location.state ? location.state.snackbarMsg : {
        open: false,
        message: '',
        severity: 'success'
    });

    const { id } = useParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/single/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            setFormTitle(res.data.data.q_title);
            setElements(res.data.data.form_json);
            setFormSlug(res.data.data.q_code);
        }
        ).catch(err => console.log(err));
    }, [id]);

    const handleSubmit = (e) => {

        if (formTitle === '') {
            setSnackbarMsg({
                open: true,
                message: 'กรุณากรอกชื่อแบบสอบถาม',
                severity: 'error'
            });
            return;
        }

        if (elements.length === 0) {
            setSnackbarMsg({
                open: true,
                message: 'กรุณาเพิ่มคำถาม',
                severity: 'error'
            });
            return;
        }

        let error = false;
        elements.forEach(element => {
            if (element.type === 'radio' || element.type === 'checkbox' || element.type === 'select') {
                if (!element.options || element.options.length === 0) {
                    error = true;
                    setSelectedElement(element);
                }
            }
        });

        if (error) {
            setSnackbarMsg({
                open: true,
                message: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                severity: 'error'
            });
            return;
        }

        setLoading(true);

        const data = {
            title: formTitle,
            elements: elements
        };

        axios.request({
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/update/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
            },
            data: data
        }).then(res => {
            setLoading(false);
            setSnackbarMsg({
                open: true,
                message: 'แบบสอบถามถูกอัพเดทแล้ว',
                severity: 'success'
            });
        }).catch(err => {
            console.log(err);
            setLoading(false);

            // If 401 Unauthorized Remove Token and Redirect to Login
            if (err.response && err.response.status === 401) {
                localStorage.removeItem('admin_token');
                localStorage.removeItem('admin_role');
                window.location.href = '/login';
                return;
            }

            let errorMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาติดต่อผู้ดูแลระบบ';
            if (err.response && err.response.data.error) {
                const errors = err.response.data.error;
                const firstErrorKey = Object.keys(errors)[0];
                errorMessage = errors[firstErrorKey];
            }

            setSnackbarMsg({
                open: true,
                message: errorMessage,
                severity: 'error'
            });
        });
    }

    return (
        <Layout>
            <div className='flex justify-between mb-8'>
                <div className='flex gap-4 items-center'>
                    <Link to='/questionnaires' className='btn btn-primary'><ArrowBackOutlinedIcon /></Link>
                    <h1 className="text-xl font-semibold">แก้ไขแบบสอบถาม</h1>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    บันทึกการเปลี่ยนแปลง
                </Button>
            </div>
            <div className='mt-4'>
                <TextField
                    label="ชื่อแบบสอบถาม"
                    variant="outlined"
                    fullWidth
                    value={formTitle}
                    onChange={(e) => setFormTitle(e.target.value)}
                />
                <a
                    href={`${process.env.REACT_APP_FRONTEND_URL}/questionnaire/${formSlug}`}
                    target='_blank'
                    rel='noreferrer'
                    className='block mt-3 ml-3 text-sm text-gray-600 underline'
                >
                    {`${process.env.REACT_APP_FRONTEND_URL}/questionnaire/${formSlug}`}
                </a>
            </div>
            <div className='mt-4'>
                <FormBuilder
                    elements={elements}
                    setElements={setElements}
                    selectedElement={selectedElement}
                    setSelectedElement={setSelectedElement}
                />
            </div>
            <Snackbar open={snackbarMsg.open} autoHideDuration={6000} onClose={() => setSnackbarMsg({ ...snackbarMsg, open: false })} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <MuiAlert elevation={6} variant="filled" onClose={() => setSnackbarMsg({ ...snackbarMsg, open: false })} severity={snackbarMsg.severity}>
                    {snackbarMsg.message}
                </MuiAlert>
            </Snackbar>
        </Layout>
    );

};

export default UpdateQuestionnaires;
