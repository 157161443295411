import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../layouts/layout";
import ReportSideBar from "../../layouts/reportSidebar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../../components/predefinedBottomRanges';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function CancellationVsTargetReport() {

    const [groupBy, setGroupBy] = useState('month');
    const [reportData, setReportData] = useState(null);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/cancellation-vs-target-report`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        groupBy: groupBy,
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setReportData(data);

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        fetchData();
    }, [filterDate, groupBy]);

    const getChartData = () => {
        if (!reportData) return null;

        const labels = reportData.currentYear.actualSales.map(item => item.group_date);
        const currentYearData = reportData.currentYear.actualSales.map(item => item.total_responses);
        const lastYearData = reportData.lastYear.actualSales.map(item => item.total_responses);

        const oneMonthAgoData = reportData.r_1_month_ago?.actualSales?.map(item => item.total_responses) || [];
        const twoMonthsAgoData = reportData.r_2_months_ago?.actualSales?.map(item => item.total_responses) || [];
        const threeMonthsAgoData = reportData.r_3_months_ago?.actualSales?.map(item => item.total_responses) || [];
        const sixMonthsAgoData = reportData.r_6_months_ago?.actualSales?.map(item => item.total_responses) || [];

        // Extracting sales targets
        const salesTargetsMap = {};
        reportData.currentYear.salesTargets.forEach(target => {
            const month = dayjs(target.period_start).format(groupBy === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM');
            salesTargetsMap[month] = target.cancellation_target;
        });
        const salesTargetsData = labels.map(label => salesTargetsMap[label] || 0);

        return {
            labels: labels,
            datasets: [
                {
                    label: 'ล่าสุด',
                    data: currentYearData,
                    backgroundColor: `#FF6384`,
                    borderColor: `#FF6384`,
                    borderWidth: 1,
                },
                {
                    label: 'เดือนก่อน',
                    data: oneMonthAgoData,
                    backgroundColor: `#36A2EB`,
                    borderColor: `#36A2EB`,
                    borderWidth: 1
                },
                {
                    label: '2 เดือนก่อน',
                    data: twoMonthsAgoData,
                    backgroundColor: `#FFCE56`,
                    borderColor: `#FFCE56`,
                    borderWidth: 1
                },
                {
                    label: '3 เดือนก่อน',
                    data: threeMonthsAgoData,
                    backgroundColor: `#4BC0C0`,
                    borderColor: `#4BC0C0`,
                    borderWidth: 1
                },
                {
                    label: '6 เดือนก่อน',
                    data: sixMonthsAgoData,
                    backgroundColor: `#FF6B6B`,
                    borderColor: `#FF6B6B`,
                    borderWidth: 1
                },
                {
                    label: 'ปีก่อน',
                    data: lastYearData,
                    backgroundColor: `#9966FF`,
                    borderColor: `#9966FF`,
                    borderWidth: 1,
                },
                {
                    label: 'เป้าโอน',
                    data: salesTargetsData,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const salesTargetsMap = {};
    if (reportData && reportData.currentYear.salesTargets) {
        reportData.currentYear.salesTargets.forEach(target => {
            const month = dayjs(target.period_start).format(groupBy === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM');
            salesTargetsMap[month] = target.cancellation_target;
        });
    }

    const addCommars = (num) => {
        if (num === null) {
            return "0.00";
        }
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(num)
    }

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='flex justify-end gap-4'>

                    <Select
                        value={groupBy}
                        onChange={(e) => setGroupBy(e.target.value)}
                        size='small'
                        sx={{
                            width: 200,
                            backgroundColor: '#fff',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e5e5ea',
                            }
                        }}
                    >
                        <MenuItem value={'day'}>รายวัน</MenuItem>
                        <MenuItem value={'month'}>รายเดือน</MenuItem>
                    </Select>

                    <DateRangePicker
                        placement="bottomEnd"
                        size="lg"
                        placeholder="เลือกช่วงเวลา"
                        value={[filterDate.startDate, filterDate.endDate]}
                        onChange={(value) => {
                            setFilterDate({
                                startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                            });
                        }}
                        ranges={predefinedBottomRanges}
                    />

                </div>
            </div>
            <div className='grid grid-cols-12'>

                <ReportSideBar />

                <div className="col-span-9">
                    <Card className='my-4'>
                        <CardContent>
                            {reportData && (
                                <Bar
                                    data={getChartData()}
                                    options={{
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                            },
                                        },
                                    }}
                                />
                            )}
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <div className='overflow-x-auto'>
                                <table className='w-full border-collapse border border-gray-300'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className='border border-gray-200 px-4 py-2 text-left'>วัน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>ล่าสุด</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>2 เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>3 เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>6 เดือนก่อน</th>
                                            <th colSpan={2} className='border border-gray-200 px-4 py-2 '>ปีก่อน</th>
                                        </tr>
                                        <tr>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดยกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>เป้ายกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดยกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>เป้ายกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดยกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>เป้ายกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดยกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>เป้ายกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดยกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>เป้ายกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>ยอดยกเลิก</th>
                                            <th className='border border-gray-200 px-4 py-2 text-right whitespace-nowrap'>เป้ายกเลิก</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData && reportData.currentYear.actualSales.map((item, index) => (
                                            <tr key={index}>
                                                <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>{item.group_date}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(item.total_responses)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(salesTargetsMap[item.group_date] || 0)}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_1_month_ago.actualSales[index]?.total_responses || 0)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_1_month_ago.salesTargets[index]?.sales_target || 0)}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_2_months_ago.actualSales[index]?.total_responses || 0)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_2_months_ago.salesTargets[index]?.sales_target || 0)}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_3_months_ago.actualSales[index]?.total_responses || 0)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_3_months_ago.salesTargets[index]?.sales_target || 0)}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_6_months_ago.actualSales[index]?.total_responses || 0)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.r_6_months_ago.salesTargets[index]?.sales_target || 0)}</td>

                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.lastYear.actualSales[index]?.total_responses || 0)}</td>
                                                <td className='border border-gray-200 px-4 py-2 text-right'>{addCommars(reportData.lastYear.salesTargets[index]?.sales_target || 0)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>

        </Layout >
    );
}
