import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from "../layouts/layout"
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AutoFixNormalOutlinedIcon from '@mui/icons-material/AutoFixNormalOutlined';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const Users = () => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [userDetail, setUserDetail] = useState({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        notify_email: [],
        password: '',
        roles: [],
        status: 'active',
    });
    const resetUserDetail = () => {
        setUserDetail({
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            notify_email: [],
            password: '',
            roles: [],
            status: 'active',
        });
    }

    const fetchcontents = useCallback((value = 1, search = null) => {
        setIsLoading(true)
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/users/get/${value}`;
        if (search) {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/users/get/${value}?search=${search}`;
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.data);
                setIsLoading(false);
                setAllPage(response.data.totalPages);
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const handleQuickUpdate = (id, status) => {
        setIsLoading(true);

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/users/update-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: JSON.stringify({
                status: status
            })
        }).then(function (response) {
            setIsLoading(false);
            fetchcontents();
            setAlert({
                open: true,
                type: 'success',
                msg: 'แก้ไขสถานะเรียบร้อยแล้ว'
            });
        }).catch(function (err) {
            setIsLoading(false);

            if (err.response && err.response.status === 401) {
                localStorage.removeItem('admin_token');
                localStorage.removeItem('admin_role');
                window.location.href = '/login';
                return;
            }

            let errorMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาติดต่อผู้ดูแลระบบ';
            if (err.response && err.response.data.error) {
                const errors = err.response.data.error;
                const firstErrorKey = Object.keys(errors)[0];
                errorMessage = errors[firstErrorKey];
            }

            setAlert({
                open: true,
                type: 'error',
                msg: errorMessage
            });
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const validateInput = (bypass) => {
        if (userDetail.first_name === '' || userDetail.last_name === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกชื่อ - นามสกุล'
            });
            return false;
        }
        if (userDetail.email === '' || !emailRegex.test(userDetail.email)) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'รูปแบบอีเมลไม่ถูกต้อง'
            });
            return false;
        }

        if (userDetail.notify_email.length > 0) {
            for (let i = 0; i < userDetail.notify_email.length; i++) {
                if (!emailRegex.test(userDetail.notify_email[i])) {
                    setAlert({
                        open: true,
                        type: 'error',
                        msg: 'รูปแบบอีเมลไม่ถูกต้อง'
                    });
                    return false;
                }
            }
        }

        if (userDetail.password === '' && bypass === 'add') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกรหัสผ่าน'
            });
            return false;
        }
        if (userDetail.roles.length === 0) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกสิทธิการใช้งาน'
            });
            return false;
        }
        return true;
    }

    const handleAddnewOrUpdate = (addOrUpdate) => {
        if (!validateInput(addOrUpdate)) {
            return;
        }
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/users/addnew`;
        let method = 'post';
        if (addOrUpdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/users/update/${userDetail.id}`;
            method = 'put';
        }
        setIsLoading(true);

        axios({
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: JSON.stringify(userDetail)
        }).then(function (response) {
            setIsLoading(false);
            fetchcontents();
            setOpenAddDialog(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'บันทึกข้อมูลสำเร็จ'
            });
            resetUserDetail();
        }).catch(function (err) {
            setIsLoading(false);

            if (err.response && err.response.status === 401) {
                localStorage.removeItem('admin_token');
                localStorage.removeItem('admin_role');
                window.location.href = '/login';
                return;
            }

            let errorMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาติดต่อผู้ดูแลระบบ';
            if (err.response && err.response.data.error) {
                const errors = err.response.data.error;
                const firstErrorKey = Object.keys(errors)[0];
                errorMessage = errors[firstErrorKey];
            }

            setAlert({
                open: true,
                type: 'error',
                msg: errorMessage
            });
        });
    }

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchcontents(1, searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        if (value === '') {
            fetchcontents();
            return;
        }
        debouncedFetchContents(value);
    }

    const converRoleToText = (role) => {
        switch (role) {
            case "ROLE_ADMIN":
                return 'ผู้ดูแลระบบ';
            case "ROLE_MANAGER":
                return 'ผู้จัดการฝ่ายขาย';
            case "ROLE_SALE":
                return 'พนักงานขาย';
            default:
                return 'ไม่ระบุ';
        }
    }

    const convertRoleArrTotext = (role) => {
        let roleText = '';

        for (let i = 0; i < role.length; i++) {
            roleText += converRoleToText(role[i]);
            if (i < role.length - 1) {
                roleText += ', ';
            }
        }
        return roleText;
    }

    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='flex gap-4 items-center'>
                        <h1 className='text-2xl font-bold hidden lg:block'>พนักงาน</h1>
                        <input
                            type='search'
                            className='border border-gray-300 rounded-md p-2 outline-none'
                            placeholder='ค้นหา'
                            onChange={handleDebonceSearch}
                        />
                    </div>
                    <div className='flex gap-4'>
                        <Button variant="contained" onClick={() => setOpenAddDialog(true)}>+ เพิ่มพนักงาน</Button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-2/12 font-bold'>ชื่อ</div>
                            <div className='w-4/12 font-bold'>อีเมล</div>
                            <div className='w-2/12 font-bold'>สิทธิการใช้งาน</div>
                            <div className='w-2/12 text-right font-bold'>สถานะ</div>
                            <div className='w-2/12 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-2/12 ' onClick={() => {
                                    setUserDetail({
                                        id: parseInt(content.id),
                                        first_name: content.first_name,
                                        last_name: content.last_name,
                                        email: content.email,
                                        notify_email: content.notify_email,
                                        password: '',
                                        roles: content.roles,
                                        status: content.status,
                                    });
                                    setOpenAddDialog(true);
                                }}>{content.first_name} {content.last_name}</div>
                                <div className='w-4/12 '>{content.email}</div>
                                <div className='w-2/12 '>
                                    {content.roles ? convertRoleArrTotext(content.roles) : 'ไม่ระบุ'}
                                </div>
                                <div className='w-2/12 text-right'>
                                    <Switch
                                        checked={content.status === 'active' ? true : false}
                                        onChange={(e) => handleQuickUpdate(content.id, e.target.checked ? 'active' : 'inactive')}
                                    />
                                </div>
                                <div className='w-2/12 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" onClick={() => {
                                            setUserDetail({
                                                id: parseInt(content.id),
                                                first_name: content.first_name,
                                                last_name: content.last_name,
                                                email: content.email,
                                                notify_email: content.notify_email,
                                                password: '',
                                                roles: content.roles,
                                                status: content.status,
                                            });
                                            setOpenAddDialog(true);

                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                open={openAddDialog}
                onClose={() => { setOpenAddDialog(false); resetUserDetail(); }}
            >
                <DialogTitle>
                    {userDetail.id === '' ? 'เพิ่มแอดมิน' : 'แก้ไขแอดมิน'}
                </DialogTitle>
                <DialogContent>
                    <div className='flex flex-col gap-4 w-96 max-w-full' >
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>ชื่อ</label>
                            <input
                                type='text'
                                className='border border-gray-300 rounded-md p-2 outline-none'
                                value={userDetail.first_name} onChange={(e) => setUserDetail({ ...userDetail, first_name: e.target.value })}
                                placeholder='ชื่อ'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>นามสกุล</label>
                            <input
                                type='text'
                                className='border border-gray-300 rounded-md p-2 outline-none'
                                value={userDetail.last_name} onChange={(e) => setUserDetail({ ...userDetail, last_name: e.target.value })}
                                placeholder='ชื่อ'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>อีเมลสำหรับเข้าสู่ระบบ และแจ้งเตือน</label>
                            <input
                                type='text'
                                className='border border-gray-300 rounded-md p-2 outline-none'
                                value={userDetail.email} onChange={(e) => setUserDetail({ ...userDetail, email: e.target.value })}
                                placeholder='กรอกอีเมลสำหรับเข้าสู่ระบบ และแจ้งเตือน'
                            />
                        </div>


                        <div className='flex flex-col gap-2'>

                            {userDetail.notify_email && userDetail.notify_email.map((email, index) => (
                                <div className='relative' key={index}>
                                    <input
                                        type='text'
                                        className='border border-gray-300 rounded-md p-2 w-full outline-none'
                                        value={email} onChange={(e) => {
                                            let notifyEmail = [...userDetail.notify_email];
                                            notifyEmail[index] = e.target.value;
                                            setUserDetail({ ...userDetail, notify_email: notifyEmail });
                                        }}
                                        placeholder='กรอกอีเมลสำหรับแจ้งเตือน'
                                    />
                                    <div className='absolute top-0 right-0'>
                                        <Tooltip title="ลบ" placement="top" arrow >
                                            <IconButton aria-label="delete" onClick={() => {
                                                let notifyEmail = [...userDetail.notify_email];
                                                notifyEmail.splice(index, 1);
                                                setUserDetail({ ...userDetail, notify_email: notifyEmail });
                                            }}>
                                                <ClearOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            ))}


                        </div>

                        <button
                            className='outline-none text-right underline w-1/2 self-end'
                            onClick={
                                () => {
                                    let notifyEmail = [...userDetail.notify_email];
                                    notifyEmail.push('');
                                    setUserDetail({ ...userDetail, notify_email: notifyEmail });
                                }
                            }
                        >
                            + เพิ่มอีเมลแจ้งเตือน
                        </button>


                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>รหัสผ่าน</label>
                            <div className='relative'>
                                <input
                                    type='text'
                                    className='border border-gray-300 rounded-md p-2 w-full outline-none'
                                    value={userDetail.password} onChange={(e) => setUserDetail({ ...userDetail, password: e.target.value })}
                                    placeholder={userDetail.id === '' ? 'กรอกรหัสผ่าน' : 'ปล่อยว่างไว้หากไม่ต้องการเปลี่ยนรหัสผ่าน'}
                                />
                                <div className='absolute top-0 right-0'>
                                    <Tooltip title="สุ่มรหัสผ่าน" placement="top" arrow >
                                        <IconButton aria-label="random" onClick={() => {
                                            let randomPassword = Math.random().toString(36).slice(-8);
                                            setUserDetail({ ...userDetail, password: randomPassword });
                                        }}>
                                            <AutoFixNormalOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>สิทธิการใช้งาน</label>
                            <Select
                                multiple
                                value={userDetail.roles}
                                onChange={(e) => setUserDetail(prev => ({ ...prev, roles: e.target.value }))}
                                input={<Input />}
                                renderValue={(selected) => selected.map(converRoleToText).join(', ')}
                            >
                                <MenuItem value="ROLE_ADMIN">ผู้ดูแลระบบ</MenuItem>
                                <MenuItem value="ROLE_MANAGER">ผู้จัดการฝ่ายขาย</MenuItem>
                                <MenuItem value="ROLE_SALE">พนักงานขาย</MenuItem>
                            </Select>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>สถานะ</label>
                            <Select
                                value={userDetail.status}
                                onChange={(e) => setUserDetail(prev => ({ ...prev, status: e.target.value }))}
                                input={<Input />}
                            >
                                <MenuItem value="active">ใช้งาน</MenuItem>
                                <MenuItem value="inactive">ระงับการใช้งาน</MenuItem>
                            </Select>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenAddDialog(false); resetUserDetail(); }}>ยกเลิก</Button>
                    <Button
                        disabled={isLoading}
                        onClick={() => {
                            if (userDetail.id === '') {
                                handleAddnewOrUpdate('add');
                            } else {
                                handleAddnewOrUpdate('update');
                            }
                        }}
                    >
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default Users;